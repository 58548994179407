.footer {
    background-color: #f1f1f1;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid #ddd;
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer p {
    margin: 5px 0;
    color: #555;
}

.footer a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.footer a:hover {
    text-decoration: underline;
}