.formContainer {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

.formGroup {
    margin-bottom: 15px;
}

.formGroup label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.formGroup input[type="text"],
.formGroup input[type="number"],
.formGroup input[type="file"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.contactInfoGroup {
    display: flex;
    gap: 10px;
}

.contactInfoGroup .formGroup {
    flex: 1 1 50%;
}

.deductionGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.deductionGroup .formGroup {
    flex: 1 1 30%;
    margin-bottom: 15px;
}

.formGroup input[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.formGroup input[type="submit"]:hover {
    background-color: #0056b3;
}

.loadingScreen {
    position: fixed;
    /* Fix it to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    background-color: rgba(0, 0, 0, 0.7);
    /* Semi-transparent dark background */
    color: white;
    /* White text */
    display: flex;
    /* Flexbox to center the content */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    font-size: 24px;
    /* Text size */
    z-index: 9999;
    /* Ensure it is on top of other elements */
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

.checkboxContainer {
    display: flex;
    align-items: center;
}

.checkboxContainer input[type="checkbox"] {
    margin-right: 8px;
}